import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import {
    IonButtons,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonItem,
    IonButton,
    IonModal,
    IonTextarea,
} from '@ionic/react'
import { Formik, FormikValues } from 'formik'
import { updateCase, createCase, selectCaseById } from '../CaseSlice'
import { isEmpty } from 'lodash'
import MyAccordion from '../../../components/MyAccordion'
import AddDataButton from '../../../components/AddDataButton'
import { RootState } from '../../../store'

type InspectionProps = {
    disabled: boolean
    dataLoading: boolean
}

type InspectionState = {
    isOpen: boolean
}

type RouterProps = RouteComponentProps<{
    caseId: string
    patientId: string
}>

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = InspectionProps & RouterProps & PropsFromRedux

class Inspection extends React.Component<Props, InspectionState> {
    state: InspectionState = {
        isOpen: false,
    }
    componentDidMount() {}

    handleSubmitData = async (values: FormikValues) => {
        const { caseId, updateCase, patientId } = this.props

        await updateCase({ data: values, id: caseId, patientId })
        this.setState({ isOpen: false })
    }

    renderModal = () => {
        const { thisCase } = this.props
        const { isOpen } = this.state

        return (
            <IonModal
                isOpen={isOpen}
                onDidDismiss={() => {
                    this.setState({ isOpen: false })
                }}
            >
                <Formik
                    initialValues={{ ...thisCase } as FormikValues}
                    onSubmit={this.handleSubmitData}
                >
                    {(props) => (
                        <form
                            onSubmit={props.handleSubmit}
                            className="full-width-form"
                        >
                            <IonHeader>
                                <IonToolbar>
                                    <IonButtons slot="start">
                                        <IonButton
                                            color="medium"
                                            onClick={() =>
                                                this.setState({ isOpen: false })
                                            }
                                        >
                                            Abbrechen
                                        </IonButton>
                                    </IonButtons>
                                    <IonTitle>Inspektion</IonTitle>
                                    <IonButtons slot="end">
                                        <IonButton onClick={props.submitForm}>
                                            Speichern
                                        </IonButton>
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent>
                                <IonItem>
                                    <IonTextarea
                                        name="inspection"
                                        label="Inspektion"
                                        labelPlacement="floating"
                                        autoGrow={true}
                                        onIonChange={props.handleChange}
                                        value={props.values.inspection ?? ''}
                                    ></IonTextarea>
                                </IonItem>
                                <IonItem>
                                    <IonTextarea
                                        name="inspectionHint"
                                        label="Relevante Hinweise aus der Inspektion und die Planung der weiteren Untersuchung"
                                        labelPlacement="floating"
                                        autoGrow={true}
                                        onIonChange={props.handleChange}
                                        value={
                                            props.values.inspectionHint ?? ''
                                        }
                                    ></IonTextarea>
                                </IonItem>
                            </IonContent>
                        </form>
                    )}
                </Formik>
            </IonModal>
        )
    }

    renderAddData = () => {
        const { disabled } = this.props

        return (
            <div className="index-main">
                <p style={{ paddingTop: '16px' }}>Inspektion aufnehmen</p>
                <AddDataButton
                    disabled={disabled}
                    onClick={() => this.setState({ isOpen: true })}
                />
            </div>
        )
    }

    renderInspection = () => {
        const { thisCase } = this.props
        const { isOpen } = this.state

        if (!thisCase) {
            return null
        }

        return (
            <>
                <p>{thisCase['inspection']}</p>
                <div
                    style={{
                        width: '100%',
                        height: '72px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <IonButton
                        fill="clear"
                        onClick={() => this.setState({ isOpen: true })}
                        disabled={isOpen}
                        style={{
                            paddingTop: '16px',
                            height: '100%',
                        }}
                    >
                        Inspektion bearbeiten
                    </IonButton>
                </div>
            </>
        )
    }

    render() {
        const { thisCase, dataLoading } = this.props

        if (dataLoading || !thisCase) {
            return null
        }

        const dataAvailable =
            thisCase.hasOwnProperty('inspection') &&
            !isEmpty(thisCase['inspection'])

        return (
            <>
                <MyAccordion
                    title="Inspektion"
                    showIcon={true}
                    checked={dataAvailable}
                >
                    {dataAvailable
                        ? this.renderInspection()
                        : this.renderAddData()}
                </MyAccordion>

                {this.renderModal()}
            </>
        )
    }
}

const mapState = (state: RootState, ownProps: RouterProps) => {
    const caseId = parseInt(ownProps.match.params.caseId)
    const patientId = parseInt(ownProps.match.params.patientId)

    return {
        thisCase: selectCaseById(state, caseId),
        caseId,
        patientId,
    }
}

const mapDispatch = { updateCase, createCase }

const connector = connect(mapState, mapDispatch)

export default withRouter(connector(Inspection))
