import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCheckbox,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonList,
    IonPage,
    IonPopover,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonSpinner,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
    SegmentValue,
} from '@ionic/react'
import { isEmpty } from 'lodash'
import {
    createDocumentation,
    selectDocumentationById,
    updateDocumentation,
} from './DocumentationSlice'
import { Formik, FormikProps, FormikValues } from 'formik'
import moment from 'moment'
import 'moment/locale/de'
import { makeTestSelectionByCase } from '../Case/selectors'
import { fetchCaseById, selectCaseById } from '../Case/CaseSlice'
import { exactSearch } from '../../utils/IcfIcdHandler'
import { informationCircleOutline } from 'ionicons/icons'
import { selectAllTests } from '../Test/TestSlice'
import { RootState } from '../../store'
import { IcfIcdModeEnum } from '../../utils/Enum/IcfIcdEnum'
import IcfButton from '../../components/IcfButton'
import MedDrawer from './MedDrawer'

type DocumentationEditProps = {}

type DocumentationEditState = {
    initialDataLoading: boolean
    popoverContent?: { name: string; label: string }
    popoverOpen?: string
    dataLoading: boolean
    segmentValue: SegmentValue
}

type RouterProps = RouteComponentProps<{
    documentationId: string
    patientId: string
    caseId: string
}>

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = DocumentationEditProps & RouterProps & PropsFromRedux

class DocumentationEdit extends React.Component<Props, DocumentationEditState> {
    state: DocumentationEditState = {
        initialDataLoading: true,
        dataLoading: false,
        segmentValue: 'documentation',
    }

    componentDidMount = async () => {
        const { fetchCaseById, caseId, patientId } = this.props

        this.setState({ initialDataLoading: true })
        await fetchCaseById({ id: caseId, patientId })
        this.setState({ initialDataLoading: false })
    }

    handleSubmitForm = async (values: FormikValues) => {
        const {
            updateDocumentation,
            createDocumentation,
            fetchCaseById,
            isNewDocumentation,
            documentationId,
            caseId,
            patientId,
            history,
        } = this.props

        try {
            if (isNewDocumentation) {
                await createDocumentation({ data: values, caseId, patientId })
            } else {
                await updateDocumentation({
                    data: values,
                    id: documentationId,
                    caseId,
                    patientId,
                })
            }
            await fetchCaseById({ id: caseId, patientId })
            this.setState({ dataLoading: false }, () => {
                history.push(`/patients/${patientId}/cases/${caseId}`)
            })
        } catch (err) {
            console.error(err)
        }
    }

    renderIcfButton = (
        icf: string,
        props: FormikProps<FormikValues>,
        index: number
    ) => {
        const { values, setFieldValue } = props
        const { caseById } = this.props

        let completeIcf = exactSearch(icf, IcfIcdModeEnum.MODE_ICF)

        if (!completeIcf) {
            return null
        }

        return (
            <IonItem
                lines="none"
                key={index}
                onClick={() => {
                    let docuIcfs = [...values.documentationIcfs] ?? []

                    if (docuIcfs.includes(icf)) {
                        docuIcfs.splice(docuIcfs.indexOf(icf), 1)
                        setFieldValue('documentationIcfs', docuIcfs)
                    } else {
                        docuIcfs.push(icf)
                        setFieldValue('documentationIcfs', docuIcfs)
                    }
                }}
            >
                <IonCheckbox
                    slot="start"
                    justify="start"
                    labelPlacement="end"
                    checked={values?.documentationIcfs?.includes(icf)}
                >
                    {completeIcf.code +
                        (caseById?.personalFactors ? 'i' : '') +
                        ' - ' +
                        completeIcf.label}
                </IonCheckbox>
            </IonItem>
        )
    }

    renderIcfSelection = (props: FormikProps<FormikValues>) => {
        const { caseById, caseId } = this.props

        return (
            <IonRow>
                <IonCol>
                    <p>
                        Auf welche ICFs bezieht sich diese Dokumentation
                        (optional)?
                    </p>
                    {caseById?.caseIcfs ? (
                        <IonList>
                            {caseById.caseIcfs.map(
                                (icf) => (
                                    <IcfButton
                                        icf={icf}
                                        key={icf}
                                        props={props}
                                        caseId={caseId}
                                    />
                                )
                                //this.renderIcfButton(icf, props, index)
                            )}
                        </IonList>
                    ) : (
                        <IonItem lines="none">
                            <IonIcon
                                color="primary"
                                size="large"
                                slot="start"
                                icon={informationCircleOutline}
                            />
                            <IonText color="primary">
                                Es wurden keine ICFs im Fall ausgewählt
                            </IonText>
                        </IonItem>
                    )}
                </IonCol>
            </IonRow>
        )
    }

    renderFieldset = (props: FormikProps<FormikValues>) => {
        const { handleChange, values, handleSubmit } = props
        const { isNewDocumentation } = this.props
        const { dataLoading } = this.state

        return (
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <div
                            className="ion-text-end"
                            style={{ paddingRight: '40px' }}
                        >
                            <IonText>
                                Datum:{' '}
                                {isNewDocumentation
                                    ? moment().format('L')
                                    : moment(values.date).format('L')}
                            </IonText>
                        </div>
                    </IonCol>
                </IonRow>
                {this.renderIcfSelection(props)}
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonTextarea
                                name="text"
                                label="Dokumentation"
                                labelPlacement="floating"
                                autoGrow={true}
                                onIonChange={handleChange}
                                value={values.text ?? ''}
                            ></IonTextarea>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonTextarea
                                name="toDoNext"
                                label="To Do Next"
                                labelPlacement="floating"
                                autoGrow={true}
                                onIonChange={handleChange}
                                value={values.toDoNext ?? ''}
                            ></IonTextarea>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonButton onClick={() => handleSubmit()}>
                            {dataLoading ? <IonSpinner /> : 'Speichern'}
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        )
    }

    render() {
        const {
            isNewDocumentation,
            documentationById,
            caseId,
            documentationId,
            match,
        } = this.props
        const {
            initialDataLoading,
            popoverContent,
            segmentValue,
            popoverOpen,
        } = this.state

        if (
            initialDataLoading ||
            (documentationId && isEmpty(documentationById))
        ) {
            return null
        }

        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar mode="ios">
                        <IonButtons slot="start">
                            <IonBackButton
                                text=""
                                defaultHref={`/patients/${match.params.patientId}/cases/${match.params.caseId}`}
                            ></IonBackButton>
                        </IonButtons>
                        <IonTitle>
                            {isNewDocumentation
                                ? 'Neue Dokumentation'
                                : 'Dokumentation: ' +
                                  moment(documentationById?.date).format('L')}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <Formik
                        enableReinitialize
                        initialValues={
                            isNewDocumentation
                                ? ({
                                      caseId: caseId,
                                      date: moment().format(),
                                      documentationIcfs: [],
                                      tests: [],
                                      rejectedTests: [],
                                  } as FormikValues)
                                : { ...documentationById }
                        }
                        onSubmit={this.handleSubmitForm}
                    >
                        {(props) => (
                            <form
                                onSubmit={props.handleSubmit}
                                className="full-width"
                            >
                                <IonSegment
                                    value={segmentValue}
                                    style={{
                                        width: '90%',
                                        margin: '0 auto',
                                        marginTop: '8px',
                                        marginBottom: '8px',
                                    }}
                                    onIonChange={(e) =>
                                        this.setState({
                                            segmentValue: e.detail.value!,
                                        })
                                    }
                                >
                                    <IonSegmentButton value="documentation">
                                        Dokumentation
                                    </IonSegmentButton>
                                    <IonSegmentButton value="drawing">
                                        Zeichenmodus
                                    </IonSegmentButton>
                                </IonSegment>
                                {segmentValue === 'documentation' ? (
                                    this.renderFieldset(props)
                                ) : (
                                    <MedDrawer />
                                )}
                            </form>
                        )}
                    </Formik>
                </IonContent>
                <IonPopover
                    isOpen={Boolean(popoverOpen)}
                    onDidDismiss={() =>
                        this.setState({ popoverOpen: undefined })
                    }
                    side="left"
                    alignment="center"
                    className="popover-content"
                >
                    <IonContent class="ion-padding">
                        <div>
                            <h3>{popoverContent?.name}</h3>
                            <p>{popoverContent?.label}</p>
                        </div>
                    </IonContent>
                </IonPopover>
            </IonPage>
        )
    }
}

const makeMapState = () => {
    const getTestSelectionByCase = makeTestSelectionByCase()

    return (state: RootState, ownProps: RouterProps) => {
        const match = ownProps.match
        const documentationId = parseInt(match.params.documentationId) ?? null
        const caseId = parseInt(match.params.caseId) ?? null
        const testSelectionByCase = getTestSelectionByCase(state, caseId)

        return {
            match: match,
            patientId: parseInt(match.params.patientId) ?? null,
            documentationId: documentationId,
            caseId: caseId,
            isNewDocumentation: match.params.documentationId === 'new',
            documentationById: selectDocumentationById(state, documentationId),
            caseById: selectCaseById(state, caseId),
            tests: selectAllTests(state),
            testsAsObject: state.test?.entities,
            testSelectionByCase: testSelectionByCase,
        }
    }
}

const mapDispatch = {
    createDocumentation,
    updateDocumentation,
    fetchCaseById,
}

const connector = connect(makeMapState, mapDispatch)

export default withRouter(connector(DocumentationEdit))
