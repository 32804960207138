import React, { ReactNode } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import {
    IonButtons,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonIcon,
    IonItem,
    IonText,
    IonButton,
    IonModal,
    IonList,
    IonTextarea,
    IonInput,
    IonSpinner,
    IonChip,
} from '@ionic/react'
import { removeCircleOutline } from 'ionicons/icons'
import { Formik, FormikValues } from 'formik'
import { isEmpty, isNaN, set } from 'lodash'
import MyAccordion from '../../../components/MyAccordion'
import DeleteDialog from '../../../components/DeleteDialog/DeleteDialog'
import AddDataButton from '../../../components/AddDataButton'
import { updateFlag, createFlag, deleteFlagById } from '../../Flag/FlagSlice'
import { fetchCaseById, createCase } from '../CaseSlice'
import { RootState } from '../../../store'
import { Flag } from '../../Flag/FlagType'
import { EntityId } from '@reduxjs/toolkit'
import { makeFlagsByCase } from '../selectors'

type FlagsProps = {
    isNewCase: boolean
}

type FlagsState = {
    deleteDialogOpen: boolean
    optionToDelete: EntityId | null
    isOpen: boolean
    accordionOpen: boolean
    flagId: EntityId | null
}

type RouterProps = RouteComponentProps<{ caseId: string; patientId: string }>

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = FlagsProps & RouterProps & PropsFromRedux

class Flags extends React.Component<Props, FlagsState> {
    state = {
        isOpen: false,
        flagId: null,
        accordionOpen: true,
        deleteDialogOpen: false,
        optionToDelete: null,
    }
    componentDidMount() {}

    submitFlagData = async (values: FormikValues, newCaseId?: EntityId) => {
        const { createFlag, updateFlag, fetchCaseById, caseId, patientId } =
            this.props
        const { flagId } = this.state

        if (newCaseId) {
            set(values, 'caseId', newCaseId)
        }

        try {
            if (flagId) {
                await updateFlag({
                    id: flagId,
                    caseId: newCaseId ?? caseId,
                    patientId,
                    data: values,
                })
            } else {
                await createFlag({
                    data: values,
                    caseId: newCaseId ?? caseId,
                    patientId,
                })
                await fetchCaseById({
                    id: caseId && !isNaN(caseId) ? caseId : newCaseId!,
                    patientId,
                })
            }
            this.setState({
                isOpen: false,
                flagId: null,
            })
        } catch (err) {
            console.error(err)
        }
    }

    handleSubmitData = async (values: FormikValues) => {
        const { history, isNewCase, createCase, patientId } = this.props

        if (isNewCase) {
            //todo if new case update route with id after save

            const response = await createCase({
                data: values,
                patientId,
            }).unwrap()
            const newCaseId = Object.keys(response.entities.case)[0]
            history.replace(`/patients/${patientId}/cases/${newCaseId}`)
            this.submitFlagData(values, newCaseId)
        } else {
            this.submitFlagData(values)
        }
    }

    deleteFlag = (id: EntityId) => {
        this.setState({ deleteDialogOpen: true, optionToDelete: id })
    }

    handleDeleteFlag = async (id: number) => {
        const { deleteFlagById, patientId, caseId } = this.props

        await deleteFlagById({ id, patientId, caseId })
        this.setState({ deleteDialogOpen: false, optionToDelete: null })
    }

    renderModal = () => {
        const { flag, caseId, patientId } = this.props
        const { isOpen, flagId } = this.state

        let modalFlagId = flagId ? parseInt(flagId) : null

        return (
            <IonModal
                isOpen={isOpen}
                onDidDismiss={() => {
                    this.setState({ isOpen: false })
                }}
            >
                <Formik
                    initialValues={
                        modalFlagId
                            ? { ...flag[modalFlagId] }
                            : ({
                                  caseId: caseId,
                                  patientId: patientId,
                              } as unknown as Flag)
                    }
                    onSubmit={this.handleSubmitData}
                >
                    {(props) => (
                        <form
                            onSubmit={props.handleSubmit}
                            className="full-width-form"
                        >
                            <IonHeader mode="ios">
                                <IonToolbar>
                                    <IonButtons slot="start">
                                        <IonButton
                                            color="medium"
                                            onClick={() =>
                                                this.setState({ isOpen: false })
                                            }
                                        >
                                            Abbrechen
                                        </IonButton>
                                    </IonButtons>
                                    <IonTitle>
                                        {modalFlagId
                                            ? `Flag: ${modalFlagId}`
                                            : 'Neue Flagge'}
                                    </IonTitle>
                                    <IonButtons slot="end">
                                        {props.isSubmitting ? (
                                            <IonSpinner />
                                        ) : (
                                            <IonButton
                                                onClick={props.submitForm}
                                            >
                                                Speichern
                                            </IonButton>
                                        )}
                                    </IonButtons>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent>
                                <div className="modal-content-padding">
                                    <IonText>
                                        <p>Farbe:</p>
                                    </IonText>
                                    <div className="flag-color-container">
                                        <IonButton
                                            fill={
                                                props.values?.color !==
                                                'darkred'
                                                    ? 'outline'
                                                    : 'solid'
                                            }
                                            color="darkred"
                                            className="flag-color-item"
                                            onClick={() => {
                                                props.setFieldValue(
                                                    'color',
                                                    'darkred'
                                                )
                                            }}
                                        >
                                            Dunkelrot
                                        </IonButton>
                                        <IonButton
                                            fill={
                                                props.values?.color !== 'red'
                                                    ? 'outline'
                                                    : 'solid'
                                            }
                                            color="danger"
                                            className="flag-color-item"
                                            onClick={() => {
                                                props.setFieldValue(
                                                    'color',
                                                    'red'
                                                )
                                            }}
                                        >
                                            Rot
                                        </IonButton>
                                        <IonButton
                                            fill={
                                                props.values?.color !== 'yellow'
                                                    ? 'outline'
                                                    : 'solid'
                                            }
                                            color="warning"
                                            className="flag-color-item"
                                            onClick={() => {
                                                props.setFieldValue(
                                                    'color',
                                                    'yellow'
                                                )
                                            }}
                                        >
                                            Gelb
                                        </IonButton>
                                        <IonButton
                                            fill={
                                                props.values?.color !== 'blue'
                                                    ? 'outline'
                                                    : 'solid'
                                            }
                                            className="flag-color-item"
                                            onClick={() => {
                                                props.setFieldValue(
                                                    'color',
                                                    'blue'
                                                )
                                            }}
                                        >
                                            Blau
                                        </IonButton>
                                        <IonButton
                                            fill={
                                                props.values?.color !== 'black'
                                                    ? 'outline'
                                                    : 'solid'
                                            }
                                            color="dark"
                                            className="flag-color-item"
                                            onClick={() => {
                                                props.setFieldValue(
                                                    'color',
                                                    'black'
                                                )
                                            }}
                                        >
                                            Schwarz
                                        </IonButton>
                                    </div>
                                    <IonItem>
                                        <IonInput
                                            name="shortDescription"
                                            label="Kurzbeschreibung"
                                            labelPlacement="floating"
                                            value={
                                                props.values
                                                    ?.shortDescription ?? ''
                                            }
                                            onIonChange={props.handleChange}
                                        ></IonInput>
                                    </IonItem>
                                    <IonItem>
                                        <IonTextarea
                                            name="description"
                                            label="Beschreibung"
                                            labelPlacement="floating"
                                            autoGrow={true}
                                            onIonChange={props.handleChange}
                                            value={
                                                props.values?.description ?? ''
                                            }
                                        ></IonTextarea>
                                    </IonItem>
                                </div>
                            </IonContent>
                        </form>
                    )}
                </Formik>
            </IonModal>
        )
    }

    renderAddData = () => {
        return (
            <div className="index-main">
                <p style={{ paddingTop: '16px' }}>Flag hinzufügen</p>
                <AddDataButton
                    onClick={() =>
                        this.setState({ isOpen: true, flagId: null })
                    }
                />
            </div>
        )
    }

    renderDeleteDialog = () => {
        const { deleteDialogOpen, optionToDelete } = this.state

        if (!optionToDelete) {
            return null
        }

        return (
            <>
                <DeleteDialog
                    header={'Flagge wirklich löschen?'}
                    onDelete={() => {
                        this.handleDeleteFlag(optionToDelete)
                    }}
                    onDidDismiss={() =>
                        this.setState({ deleteDialogOpen: false })
                    }
                    isOpen={deleteDialogOpen}
                />
            </>
        )
    }

    renderFlag = (flag: Flag, index: number) => {
        const flagMapping: Record<string, ReactNode> = {
            darkred: <IonChip color="darkred">Dunkelrot</IonChip>,
            red: <IonChip color="danger">Rot</IonChip>,
            yellow: <IonChip color="warning">Gelb</IonChip>,
            blue: <IonChip color="primary">Blau</IonChip>,
            black: <IonChip color="dark">Schwarz</IonChip>,
        }

        return (
            <IonItem
                key={index}
                button
                onClick={() => {
                    this.setState({ flagId: flag.id, isOpen: true })
                }}
            >
                {flagMapping[flag.color]}{' '}
                <IonText>
                    <p>{flag.shortDescription}</p>
                </IonText>
                <IonButton
                    onClick={(e) => {
                        e.stopPropagation()
                        this.deleteFlag(flag.id)
                    }}
                    shape="round"
                    fill="clear"
                    slot="end"
                >
                    <IonIcon
                        color="danger"
                        slot="icon-only"
                        icon={removeCircleOutline}
                    />
                </IonButton>
            </IonItem>
        )
    }

    renderFlags = () => {
        const { flagsByCase } = this.props

        return (
            <>
                <IonList>{flagsByCase!.map(this.renderFlag)}</IonList>
                {this.renderAddData()}
                {this.renderDeleteDialog()}
            </>
        )
    }

    render() {
        const { flagsByCase } = this.props

        const dataAvailable = flagsByCase && !isEmpty(flagsByCase)
        return (
            <>
                <MyAccordion
                    title={`Flags (${
                        dataAvailable ? flagsByCase.length : '0'
                    })`}
                >
                    {dataAvailable ? this.renderFlags() : this.renderAddData()}
                </MyAccordion>
                {this.renderModal()}
            </>
        )
    }
}

const makeMapState = () => {
    const selectFlagsByCase = makeFlagsByCase()

    return (state: RootState, ownProps: RouterProps) => {
        const caseId = parseInt(ownProps.match.params.caseId)
        const patientId = parseInt(ownProps.match.params.patientId)
        const flagsByCase = selectFlagsByCase(state, caseId)
        const {
            flag: { entities: flag },
        } = state

        return {
            flag: flag,
            flagsByCase: flagsByCase,
            patientId: patientId,
            caseId: caseId,
        }
    }
}

const mapDispatch = {
    updateFlag,
    createFlag,
    fetchCaseById,
    deleteFlagById,
    createCase,
}

const connector = connect(makeMapState, mapDispatch)

export default withRouter(connector(Flags))
