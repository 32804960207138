import { Layer, Line, Stage, Image } from 'react-konva'
import { useCallback, useEffect, useRef, useState } from 'react'
import { KonvaEventObject } from 'konva/lib/Node'
import docuimg from '../../assets/docuimg.png'

type Lines = { tool: string; points: number[] }[]

function MedDrawerPage() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [tool, setTool] = useState('pen')
    const [lines, setLines] = useState<Lines>([])
    const [image, setImage] = useState<HTMLImageElement>()
    const isDrawing = useRef(false)

    const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
        isDrawing.current = true
        const pos = e.target.getStage()?.getPointerPosition()
        if (pos) {
            setLines([...lines, { tool, points: [pos.x, pos.y] }])
        }
    }

    const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
        // no drawing - skipping
        if (!isDrawing.current) {
            return
        }
        const stage = e.target.getStage()
        if (!stage) {
            return
        }
        const point = stage.getPointerPosition()
        if (!point) {
            return
        }
        let lastLine = lines[lines.length - 1]
        // add point
        lastLine.points = lastLine.points.concat([point.x, point.y])

        // replace last
        lines.splice(lines.length - 1, 1, lastLine)
        setLines(lines.concat())
    }

    const handleMouseUp = () => {
        isDrawing.current = false
    }

    const handleTouchStart = useCallback(
        (e: KonvaEventObject<TouchEvent>) => {
            isDrawing.current = true
            const pos = e.target.getStage()?.getPointerPosition()
            if (pos) {
                setLines([...lines, { tool, points: [pos.x, pos.y] }])
            }
        },
        [lines, tool]
    )

    const handleTouchMove = useCallback(
        (e: KonvaEventObject<TouchEvent>) => {
            // no drawing - skipping
            if (!isDrawing.current) {
                return
            }
            const stage = e.target.getStage()
            if (!stage) {
                return
            }
            const point = stage.getPointerPosition()
            if (!point) {
                return
            }
            let lastLine = lines[lines.length - 1]
            // add point
            lastLine.points = lastLine.points.concat([point.x, point.y])

            // replace last
            lines.splice(lines.length - 1, 1, lastLine)
            setLines(lines.concat())
        },
        [lines]
    )

    const handleTouchEnd = useCallback(() => {
        isDrawing.current = false
    }, [])

    useEffect(() => {
        const tempimage = new window.Image()
        tempimage.src = docuimg
        setImage(tempimage)
    }, [])

    const ar = 411 / 575
    let height, width
    if (window.innerWidth * ar > 0.75 * window.innerHeight) {
        height = 0.75 * window.innerHeight
        width = (height * 1) / ar
    } else {
        width = window.innerWidth
        height = width * ar
    }

    return (
        <div
            id="med-drawer-container"
            style={{
                padding: '8px',
                width: width,
                height: height,
            }}
        >
            <Stage
                width={width}
                height={height}
                onMouseDown={handleMouseDown}
                onMousemove={handleMouseMove}
                onMouseup={handleMouseUp}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <Layer>
                    <Image
                        x={0}
                        y={0}
                        width={width}
                        height={height}
                        image={image}
                    />
                    {/*<Text text="Just start drawing" x={5} y={30} />*/}
                    {lines.map((line, i) => (
                        <Line
                            key={i}
                            points={line.points}
                            stroke="#df4b26"
                            strokeWidth={2}
                            tension={0.5}
                            lineCap="round"
                            lineJoin="round"
                            globalCompositeOperation={
                                line.tool === 'eraser'
                                    ? 'destination-out'
                                    : 'source-over'
                            }
                        />
                    ))}
                </Layer>
            </Stage>
            {/*<select
                value={tool}
                onChange={(e) => {
                    setTool(e.target.value)
                }}
            >
                <option value="pen">Pen</option>
                <option value="eraser">Eraser</option>
            </select>*/}
        </div>
    )
}

export default MedDrawerPage
